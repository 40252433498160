/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useRef, useState } from "react";
import tw, { css, theme } from "twin.macro";
import { graphql } from "gatsby";
import { ArticleGrid, Footer, Go, Grid, Layout } from "~components";
import { useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

const ThankYouPage = ({ data, location }) => {
  // ==========================================================================
  // context / ref / state

  const { colours, colourTransitionCSS } = useTheme();

  const [articles, setArticles] = useState([]);

  // ==========================================================================
  // variables

  const { sanityThanksPage, allSanityArticle } = data;

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    if (articles?.[0] || !allSanityArticle?.edges?.[0]) {
      return;
    }

    setArticles(allSanityArticle.edges.map(({ node }) => node));
  }, [allSanityArticle]);

  // ==========================================================================
  // render

  return (
    <>
      {/* <SEO customTitle="Home" path={location.pathname} /> */}

      <Layout>
        <div
          css={[
            css`
              ${colourTransitionCSS};

              padding-top: 56px;

              ${MEDIA_QUERIES?.desktop} {
                padding-top: 90px;
              }
            `
          ]}
        >
          <section
            css={[
              css`
                background: ${colours.background};
                color: ${colours.foreground};
              `,
              tw`w-full relative block`
            ]}
          >
            <Grid node="article">
              <div
                css={[
                  tw`col-span-6 col-start-4 relative pt-20 px-8 pb-24 text-center`
                ]}
              >
                <h1
                  css={[
                    css`
                      transform: translate3d(0, 1rem, 0);
                      opacity: 0;
                      animation: 1s cubic-bezier(0.215, 0.61, 0.355, 1)
                        appear-up forwards;
                    `,
                    tw`mt-4 font-head text-h2-md`
                  ]}
                >
                  Thank you
                </h1>

                <p
                  css={[
                    css`
                      transform: translate3d(0, 1rem, 0);
                      opacity: 0;
                      animation: 1s cubic-bezier(0.215, 0.61, 0.355, 1)
                        appear-up forwards;
                      animation-delay: 0.25s;
                    `,
                    tw`mt-6 font-body text-h4-md`
                  ]}
                >
                  {sanityThanksPage?.heading || `Welcome to The WIP!`}
                </p>

                <p
                  css={[
                    css`
                      transform: translate3d(0, 1rem, 0);
                      opacity: 0;
                      animation: 1s cubic-bezier(0.215, 0.61, 0.355, 1)
                        appear-up forwards;
                      animation-delay: 0.5s;
                    `,
                    tw`mt-2 font-body text-h4-md`
                  ]}
                >
                  {sanityThanksPage?.body}
                </p>
              </div>
            </Grid>
          </section>

          <section
            css={[
              css`
                background: ${colours.foreground};
                color: ${colours.background};
              `,
              tw`w-full relative block`
            ]}
          >
            <ArticleGrid articles={articles} max={4} />
          </section>
        </div>
      </Layout>

      <Footer />
    </>
  );
};

export default ThankYouPage;

export const query = graphql`
  query ThankYouPage {
    sanityThanksPage {
      body
      heading
    }

    allSanityArticle {
      edges {
        node {
          _id

          availability
          title
          excerpt
          readTime
          cost
          tokenKey

          slug {
            current
          }

          type {
            title
          }

          theme {
            backgroundColour {
              hex
            }
            foregroundColour {
              hex
            }
            buttonColour
          }

          category {
            name
          }

          cardImage {
            asset {
              gatsbyImageData(width: 1200, placeholder: NONE)
            }
            altText
          }
        }
      }
    }
  }
`;
